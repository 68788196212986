import React from 'react';
import './Ztilt.css';

const Ztilt: React.FC = () => {
    return (
        <div className="Ztilt-container">
            <h1>Default Title</h1>
            <p>Default paragraph.</p>
        </div>
    );
};

export default Ztilt;