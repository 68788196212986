import React from 'react';
import './Calibration.css';
import { Link } from 'react-router-dom';
// import PrinterThumbnail from '../../assets/printer.png'
// import FilamentThumbnail from '../../assets/filament.png'

const Calibration: React.FC = () => {
    return (
        <div className="calibration-container">
            <h1>Calibration</h1>
            <p>Learn how I calibrate my printers and filament!</p>
            <div className="projects-grid">
                <Link to="/3dprinting/calibration/printer" className="project-card">
                    <h2>Printer</h2>
                    {/* <img src={PrinterThumbnail} alt="3MeloD Image" className="thumbnail" /> */}
                    <p>Tune your 3D printer</p>
                </Link>
                <Link to="/3dprinting/calibration/filament" className="project-card">
                    <h2>Filament</h2>
                    {/* <img src={FilamentThumbnail} alt="Website Image" className="thumbnail" /> */}
                    <p>Calibrate your filament!</p>
                </Link>
                {/* Add more project links as needed */}
                {/* <Link to="/projects/camera_slider" className="project-card">
                    <h2>Camera Slider</h2>
                    <p>Build a motorized camera slider for smooth video motion.</p>
                </Link> */}
            </div>
        </div>
    );
};

export default Calibration;
