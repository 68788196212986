import React, { useState } from 'react';
import './Header.css';
import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
    // State for 3D Printing dropdown
    const [is3DPrintingOpen, set3DPrintingOpen] = useState(false);
    const [is3DPrintingFadingOut, set3DPrintingFadingOut] = useState(false);

    // State for Projects dropdown
    const [isProjectsOpen, setProjectsOpen] = useState(false);
    const [isProjectsFadingOut, setProjectsFadingOut] = useState(false);

    // Handlers for 3D Printing dropdown
    const handle3DPrintingEnter = () => {
        set3DPrintingFadingOut(false);
        set3DPrintingOpen(true);
    };

    const handle3DPrintingLeave = () => {
        set3DPrintingFadingOut(true);
        setTimeout(() => {
            set3DPrintingOpen(false);
        }, 300); // Match CSS transition duration
    };

    // Handlers for Projects dropdown
    const handleProjectsEnter = () => {
        setProjectsFadingOut(false);
        setProjectsOpen(true);
    };

    const handleProjectsLeave = () => {
        setProjectsFadingOut(true);
        setTimeout(() => {
            setProjectsOpen(false);
        }, 300); // Match CSS transition duration
    };

    return (
        <header className="header">
            <div className="logo-title-container">
                <Link to="/" className="nav-link">
                    <img src={logo} alt="Logo" className="profile-image" />
                </Link>
                <Link to="/" className="nav-link">
                    <h1>Spencer's Desk</h1>
                </Link>
            </div>
            <nav className="nav-buttons">
                {/* 3D Printing Dropdown */}
                <div
                    className="dropdown"
                    onMouseEnter={handle3DPrintingEnter}
                    onMouseLeave={handle3DPrintingLeave}
                >
                    <Link to="/3dprinting">
                        <button
                            className={`button ${
                                is3DPrintingOpen
                                    ? is3DPrintingFadingOut
                                        ? 'dropdown-fading-out'
                                        : 'dropdown-open'
                                    : ''
                            }`}
                        >
                            3D Printing
                        </button>
                    </Link>
                    <div
                        className={`dropdown-menu ${
                            is3DPrintingOpen
                                ? is3DPrintingFadingOut
                                    ? 'fade-out'
                                    : 'show'
                                : ''
                        }`}
                    >
                        <Link to="/3dprinting/calibration">
                            <button>Calibration</button>
                        </Link>
                        {/* <Link to="/3dprinting/klipper">
                            <button>Klipper</button>
                        </Link> */}
                        {/* <Link to="/3dprinting/project3">
                            <button>Project 3</button>
                        </Link> */}
                    </div>
                </div>

                {/* Projects Dropdown */}
                <div
                    className="dropdown"
                    onMouseEnter={handleProjectsEnter}
                    onMouseLeave={handleProjectsLeave}
                >
                    <Link to="/projects">
                        <button
                            className={`button ${
                                isProjectsOpen
                                    ? isProjectsFadingOut
                                        ? 'dropdown-fading-out'
                                        : 'dropdown-open'
                                    : ''
                            }`}
                        >
                            Projects
                        </button>
                    </Link>
                    <div
                        className={`dropdown-menu ${
                            isProjectsOpen
                                ? isProjectsFadingOut
                                    ? 'fade-out'
                                    : 'show'
                                : ''
                        }`}
                    >
                        <Link to="/projects/3meloD">
                            <button>3MeloD</button>
                        </Link>
                        <Link to="/projects/website">
                            <button>spencersdesk.com</button>
                        </Link>
                        {/* Commented for future use */}
                        {/*
                        <Link to="/projects/project2">
                            <button>Project 2</button>
                        </Link>
                        <Link to="/projects/project3">
                            <button>Project 3</button>
                        </Link>
                        */}
                    </div>
                </div>

                {/* Other Nav Links */}
                <Link to="/about">
                    <button>About</button>
                </Link>
                <Link to="/contact">
                    <button>Contact</button>
                </Link>
            </nav>
        </header>
    );
};

export default Header;
