import React from 'react';
import { Link } from 'react-router-dom';
import './ThreeDPrinting.css';
// import KlipperThumbnail from '../../assets/klipper.png'


const ThreeDPrinting: React.FC = () => {
  return (
    <div className="three-d-printing-container">
      <h1>3D Printing</h1>
      <p>Welcome to the 3D printing projects page! Here, you'll find details about my custom 3D printing builds, tutorials, and resources.</p>
      <p>Explore various projects that bring ideas to life using 3D printers, from simple tools to complex mechanisms.</p>
      <div className="projects-grid">
                <Link to="/3dprinting/mods" className="project-card">
                    <h2>Mods</h2>
                    {/* <img src={ThreeMeloDThumbnail} alt="3MeloD Image" className="thumbnail" /> */}
                    <p>Mod your 3D printer!</p>
                </Link>
                <Link to="/3dprinting/calibration" className="project-card">
                    <h2>Calibration</h2>
                    {/* <img src={ThreeMeloDThumbnail} alt="3MeloD Image" className="thumbnail" /> */}
                    <p>Learn to setup your printers and calibrate your filament!</p>
                </Link>
                <Link to="/3dprinting/klipper" className="project-card">
                    <h2>Klipper</h2>
                    {/* <img src={KlipperThumbnail} alt="Klipper Logo" className="thumbnail" /> */}
                    <p>Klipper specific topics!</p>
                </Link>
                {/* Add more project links as needed */}
                {/* <Link to="/projects/camera_slider" className="project-card">
                    <h2>Camera Slider</h2>
                    <p>Build a motorized camera slider for smooth video motion.</p>
                </Link> */}
            </div>
    </div>
  );
};

export default ThreeDPrinting;
