import React from 'react';
import { Link } from 'react-router-dom';
import './Projects.css';
import WebsiteThumbnail from '../../assets/website.png'
import ThreeMeloDThumbnail from '../../assets/3meloD.png'

const Projects: React.FC = () => {
    return (
        <div className="projects-container">
            <h1>Projects</h1>
            <p>Explore my latest creations and experiments!</p>
            <div className="projects-grid">
                <Link to="/projects/3meloD" className="project-card">
                    <h2>3MeloD</h2>
                    <img src={ThreeMeloDThumbnail} alt="3MeloD Image" className="thumbnail" />
                    <p>Turn your 3D Printer into a musical instrument!</p>
                </Link>
                <Link to="/projects/website" className="project-card">
                    <h2>spencersdesk.com</h2>
                    <img src={WebsiteThumbnail} alt="Website Image" className="thumbnail" />
                    <p>All about the website you're currently interacting with!</p>
                </Link>
                {/* Add more project links as needed */}
                {/* <Link to="/projects/camera_slider" className="project-card">
                    <h2>Camera Slider</h2>
                    <p>Build a motorized camera slider for smooth video motion.</p>
                </Link> */}
            </div>
        </div>
    );
};

export default Projects;
