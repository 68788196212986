import React from 'react';
import './SpencersDeskSite.css';

// Image imports for logos
import dockerLogo from '../../assets/docker-logo.png';
import hostingerLogo from '../../assets/hostinger-logo.png';
import nginxLogo from '../../assets/nginx-logo.png';
import reactLogo from '../../assets/react-logo.png';
import flaskLogo from '../../assets/flask-logo.png';
import gunicornLogo from '../../assets/gunicorn-logo.png';
import certbotLogo from '../../assets/certbot-logo.png';
import fail2banLogo from '../../assets/fail2ban-logo.png';
import letsEncryptLogo from '../../assets/letsencrypt-logo.png';

const SpencersDeskSite: React.FC = () => {
    return (
        <div className="website-page">
            <h1>About This Website</h1>
             {/* Introduction Section */}
             <section className="intro">
                <p>
                    Welcome to <strong>Spencer's Desk</strong>! This project started as a way to make&nbsp;<a href="/projects/3melod">3MeloD </a> accessible
                    to everyone. Initially, I assumed I could 
                    use a website builder like Squarespace, but I quickly
                    realized the need for a more custom approach.
                </p>
                <p>
                    The challenge was enabling users to choose options dynamically and feed them into the 
                    3MeloD script. After learning about the concepts of a <em>frontend</em> and
                    a <em>backend</em>, I discovered I’d also need a hosted server 
                    to manage both components effectively. Without the option to host a server at home, 
                    I turned to renting a virtual private server. This led me down the rabbit hole of 
                    web development—and this website is the result!
                </p>
            </section>

            {/* Hosting Section */}
            <section className="left-aligned">
                <h2>
                    Hosting on a Virtual Server with 
                    {/* <img src={hostingerLogo} alt="Hostinger Logo" className="title-logo" /> */}
                    <a href="https://hostinger.com?REFERRALCODE=QOCRUBYTUFP0" target="_blank" rel="noopener noreferrer">
                        <img src={hostingerLogo} alt="Hostinger Logo" className="title-logo" />
                    </a>
                </h2>
                <p>
                    The website is hosted on a <strong>virtual server</strong> provided by <strong>Hostinger</strong>. 
                    A VPS gives me full control over the server environment, ensuring flexibility and security for 
                    the application.
                </p>
                <p>
                    (The Hostinger link is a referral link meaning I get a commission off of your purchase
                    if you decide to host your own VPS or website!)
                </p>
            </section>

            <section className="left-aligned">
                <h2>
                    {/* <img src={hostingerLogo} alt="Hostinger Logo" className="title-logo" /> */}
                    <a href="https://www.docker.com" target="_blank" rel="noopener noreferrer">
                    <img src={dockerLogo} alt="Docker Logo" className="title-logo" />
                    </a>
                    for Containerization
                </h2>
                <p>
                    The entire application stack is containerized using <strong>Docker</strong>. Each component 
                    (frontend, backend, etc.) runs in its own container, creating isolated, 
                    modular environments that are easy to manage and scale.
                </p>
                
            </section>

            <section className="left-aligned">
                <h2>
                    <a href="https://nginx.org/" target="_blank" rel="noopener noreferrer">
                        <img src={nginxLogo} alt="NGINX Logo" className="nginx-logo" />
                    </a>
                    as a Reverse Proxy
                </h2>
                <p>
                    An instance of <strong>NGINX</strong> acts as the <strong>reverse proxy</strong>. It handles 
                    incoming traffic, directing requests to either the frontend or backend as needed.
                </p>
                
            </section>

            <section className="left-aligned">
                <h2>Frontend with React
                    <a href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
                        <img src={reactLogo} alt="React Logo" className="title-logo" />
                    </a>
                    and
                    <a href="https://nginx.org/" target="_blank" rel="noopener noreferrer">
                        <img src={nginxLogo} alt="NGINX Logo" className="nginx-logo" />
                    </a>
                </h2>
                <p>
                    The frontend is built with <strong>React</strong> and served by NGINX. This combination provides 
                    a fast, scalable, and dynamic user experience.
                </p>
                
            </section>

            <section className="left-aligned">
                <h2>Backend with
                    <a href="https://flask.palletsprojects.com" target="_blank" rel="noopener noreferrer">
                        <img src={flaskLogo} alt="Flask Logo" className="title-logo" />
                    </a>
                    and
                    <a href="https://gunicorn.org" target="_blank" rel="noopener noreferrer">
                        <img src={gunicornLogo} alt="Gunicorn Logo" className="title-logo" />
                    </a>
                </h2>
                <p>
                    The backend uses <strong>Flask</strong> for application logic and <strong>Gunicorn</strong>, 
                    a high-performance WSGI HTTP server, to handle requests efficiently.
                </p>
                
                
            </section>

            {/* Let's Encrypt Section */}
            <section className="left-aligned">
                <h2>
                    <a href="https://letsencrypt.org/" target="_blank" rel="noopener noreferrer">
                        <img src={letsEncryptLogo} alt="Let's Encrypt Logo" className="small-logo" />
                    </a>
                    and
                    <a href="https://certbot.eff.org/" target="_blank" rel="noopener noreferrer">
                        <img src={certbotLogo} alt="Certbot Logo" className="small-logo" />
                    </a>
                    for Free SSL Certificates
                </h2>
                <p>
                    <strong>Let's Encrypt</strong> is a free, automated, and open certificate authority 
                    that provides SSL certificates for securing websites with HTTPS. This service is vital for 
                    ensuring data integrity and privacy between users and the website.
                </p>
                <p>
                    By using Let's Encrypt, I can provide secure, encrypted connections to this website at no 
                    cost, which is essential for protecting sensitive data such as login credentials and user 
                    interactions. The SSL certificates are automatically renewed, ensuring that encryption is 
                    always up to date.
                </p>
                <p>
                    This site uses <strong>Certbot</strong> to manage 
                    free SSL certificates from <strong>Let's Encrypt</strong>. Certbot automates 
                    the process of obtaining and renewing certificates, ensuring all communication 
                    between the website and its users is encrypted.
                </p>
                <p>
                    Setting up Certbot with NGINX was straightforward (until docker got involved) and automated renewal ensures 
                    that certificates are always up-to-date without manual intervention.
                </p>
                <p>
                    I tested the SSL implementation via SSLLabs and scored an A+. Feel free to check
                    the <a href="https://www.ssllabs.com/ssltest/analyze.html?d=spencersdesk.com" target="_blank" rel="noopener noreferrer">
                        results
                    </a> or run the test yourself!
                </p>
                
            </section>


            {/* Fail2ban Section */}
            <section className="left-aligned">
                <h2>                
                    <a href="https://www.fail2ban.org/" target="_blank" rel="noopener noreferrer">
                        <img src={fail2banLogo} alt="Fail2ban Logo" className="small-logo" />
                    </a>
                    for Brute-Force Protection
                </h2>
                <p>
                    To protect the server from malicious login attempts and brute-force attacks, 
                    I’ve implemented <strong>Fail2ban</strong>. This tool monitors server logs for 
                    suspicious activity and temporarily bans IP addresses that exhibit bad behavior.
                </p>
                <p>
                    Fail2ban significantly reduces the risk of unauthorized access and integrates 
                    seamlessly with services like SSH and NGINX, adding an extra layer of security 
                    to the server environment.
                </p>

            </section>

            {/* Architecture and Learn More Sections */}
            <section className="architecture">
                <h2>Why This Architecture?</h2>
                <p>
                    The combination of Docker, NGINX, React, Flask, and Gunicorn creates a modular, secure, 
                    and scalable architecture. Each tool plays a unique role:
                    Docker isolates components, NGINX routes traffic efficiently, React builds the frontend, 
                    and Flask/Gunicorn power the backend.
                </p>
            </section>
            <section className="learn-more">
                <p>
                    Interested in the technical details or have questions? Feel free
                    to&nbsp;<a href="/contact">reach out</a>—I’d love to share what I’ve learned during this journey!
                </p>
            </section>
        </div>
    );
};

export default SpencersDeskSite;
