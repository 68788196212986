// Dependnecies
import React from 'react';
import { Route, Routes } from 'react-router-dom'; // Don't need to import Router here
// Styling
import './App.css';
// Fundamental Pages
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import About from './pages/About';
import Contact from './pages/Contact';
// Components
import Header from './components/Header'; // Import the Header component
import Footer from './components/Footer';
// 3D_Printing Pages
import ThreeDPrinting from './pages/ThreeDPrinting/ThreeDPrinting';
    // Modification
// import Mods from './pages/ThreeDPrinting/Modification/Modification';
// import ModsLinearRails from './pages/ThreeDPrinting/Modification/LinearRails';
    // Calibration
import Calibration from './pages/ThreeDPrinting/Calibration/Calibration';
    // Klipper
// import Klipper from './page/ThreeDPrinting/Klipper/Klipper.tsx';
import Ztilt from './pages/ThreeDPrinting/Calibration/Printer/Ztilt'
// Projects Pages
import Projects from './pages/Projects/Projects';
import ThreeMeloDPage from './pages/Projects/ThreeMeloDPage';
import SpencersDeskSite from './pages/Projects/SpencersDeskSite';
// import CameraSlider from './pages/Projects/CameraSlider';

const App: React.FC = () => {
    return (
        <div className="App">
            <Header /> {/* Render the Header component here */}
            <div className="main-content">
                {/* Add your Routes and main content here */}
                <Routes>
                    {/* Fundamentals */}
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    {/* 3D_Printing */}
                    <Route path="/3dprinting" element={<ThreeDPrinting />} />
                    <Route path="/3dprinting/calibration" element={<Calibration />} />
                    <Route path="/3dprinting/calibration/printer/z_tilt" element={<Ztilt />} />
                    {/* Projects */}
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/projects/3meloD" element={<ThreeMeloDPage />} />
                    <Route path="/projects/website" element={<SpencersDeskSite />} />
                    {/* <Route path="/projects/camera_slider" element={<CameraSlider />} /> */}
                    {/* 404 Not Found */}
                    <Route path="*" element={<NotFound />} /> {/* Catch-all 404 page */}
                </Routes>
            </div>
            <Footer /> {/* Render the Footer component here */}
        </div>
    );
};

export default App;
