import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
        <div className="kofi-container">
            {/* Ko-fi Button on the left side */}
            <a href="https://ko-fi.com/spencersdesk" target="_blank" rel="noopener noreferrer" className="kofi-link">
                <button className="kofi-button">
                    <i className="fa fa-coffee"></i> Buy me a Coffee!
                </button>
            </a>
        </div>

        {/* Footer content centered */}
        <div className="footer-content">
            &copy; 2024 Spencer's Desk. All Rights Reserved.
            <div className="social-links">
                <a href="https://www.youtube.com/@SpencersDesk" target="_blank" rel="noopener noreferrer">YouTube</a>
                <a href="https://github.com/Spencers-Desk" target="_blank" rel="noopener noreferrer">GitHub</a>
                <a href="https://discord.com/invite/3Sz4ExTqy4" target="_blank" rel="noopener noreferrer">Discord</a>
            </div>
        </div>
    </footer>
  );
}

export default Footer;
