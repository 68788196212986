import React from 'react';
import './Home.css';

const Home: React.FC = () => {
    return (
        <div className="home-container">
            <h1>Welcome to Spencer's Desk</h1>
            <p>Your hub for 3D Printing, DIY Projects, and Creative Adventures.</p>
            <div className="home-content">
                <img
                    src="https://via.placeholder.com/600x300"
                    alt="Placeholder for a featured project"
                    className="home-image"
                />
                <p>
                    Dive into my latest projects, tutorials, and explorations in tech and creativity. Whether you're
                    looking for inspiration or detailed guides, you've come to the right place.
                </p>
            </div>
        </div>
    );
};

export default Home;
